<template>
  <v-app>
    <v-card class="elevation-2">
      <v-card-title class="bg-dark text-white" primary-title>
        <v-col class="text-center p-0 font-weight-bold">
          Manejo de Usuarios
        </v-col>
      </v-card-title>
      <div class="p-3">
        <v-card-title>
          Lista de Usuarios
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchUsers"
            label="Buscador por coincidencias"
            append-icon="mdi-magnify"
            single-line
            hide-details
          ></v-text-field>
          <b-col md="2" v-if="role == 1">
            <export-excel
              class="btn btn-success btn-block"
              :data="list_data"
              worksheet="Lista de Usuarios"
              type="csv"
              name="lista_De_Usuarios.xls"
            >
              <strong style="font-size: 18px"
                >Excel <i class="fas fa-file-download"></i
              ></strong>
            </export-excel>
          </b-col>
        </v-card-title>
        <v-data-table
          :headers="headerUsers"
          :items="usuarios"
          :search="searchUsers"
          class="border"
          :loading="usuarios.length == 0"
          loading-text="Cargando... Por favor Espere!!"
        >
          <template v-slot:[`item.accion`]="{ item }">
            <b-button
              small
              class="text-white m-1"
              variant="info"
              @click="verInfo(item)"
              v-b-modal.userinfoModal
            >
              <i class="fas fa-user-cog"></i>
              Más opciones
            </b-button>
            <b-button
              v-if="item.alegra_id == '0'"
              small
              class="text-white m-1"
              variant="danger"
              @click="crearClienteAlegra(item)"
            >
              <i class="fas fa-user-plus"></i>
              Crear Cliente Alegra
              <b-spinner
                :id="'spn' + item.id"
                small
                hidden
                label="Spinning"
              ></b-spinner>
            </b-button>
          </template>
        </v-data-table>
        <b-modal
          id="userinfoModal"
          title="Información de Usuario"
          size="lg"
          content-class="shadow"
          hide-footer
          centered
          no-close-on-backdrop
        >
          <b-row>
            <b-col>
              <!-- Form -->
              <b-form @submit="actualizarDatosUser" v-if="show">
                <b-row>
                  <b-col>
                    <b-form-group
                      id="nombre"
                      label="Nombre"
                      label-for="input-nombre"
                    >
                      <b-form-input
                        id="input-nombre"
                        class="mr-3"
                        v-model="selectUser.nombre"
                        type="text"
                        :disabled="ctrledit"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      id="apellido"
                      label="Apellido"
                      label-for="input-apellido"
                    >
                      <b-form-input
                        id="input-apellido"
                        class="mr-3"
                        v-model="selectUser.apellido"
                        type="text"
                        :disabled="ctrledit"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      id="correo"
                      label="Correo"
                      label-for="input-correo"
                    >
                      <b-form-input
                        id="input-correo"
                        class="mr-3"
                        v-model="selectUser.correo"
                        type="text"
                        :disabled="ctrledit"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      id="cedula"
                      label="Cédula"
                      label-for="input-cedula"
                    >
                      <b-form-input
                        id="input-cedula"
                        class="mr-3"
                        v-model="selectUser.cedula"
                        type="text"
                        :disabled="ctrledit"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col>
                                        <b-form-group
                                            id="tipo_perfil"
                                            label="Tipo de Perfil"
                                            label-for="input-perfil"
                                        >
                                            <b-form-input
                                                id="input-perfil"
                                                class="mr-3"
                                                v-model="selectUser.tipo_perfil"
                                                type="text"
                                                :disabled="ctrledit"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col> -->
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      id="telefono"
                      label="Teléfono"
                      label-for="input-telefono"
                    >
                      <b-form-input
                        id="input-telefono"
                        class="mr-3"
                        v-model="selectUser.telefono"
                        type="text"
                        :disabled="ctrledit"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      id="fechaNacimiento"
                      label="Fecha de Nacimiento"
                      label-for="input-nacimiento"
                    >
                      <b-form-input
                        id="input-nacimiento"
                        class="mr-3"
                        v-model="selectUser.fecha_nacimiento"
                        type="date"
                        :disabled="ctrledit"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      id="plan"
                      label="Tipo de Plan"
                      label-for="input-plan"
                    >
                      <b-form-select
                        id="input-plan"
                        name="input-plan"
                        v-model="selectUser.tipo_plan"
                        :options="options"
                        class="mt-0 pt-0"
                        :disabled="ctrledit"
                        required
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      id="casillero"
                      label="Casillero"
                      label-for="input-casillero"
                    >
                      <b-form-input
                        id="input-casillero"
                        class="mr-3"
                        v-model="selectUser.casillero"
                        type="text"
                        :disabled="ctrledit"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="d-flex justify-content-center">
                    <b-button
                      id="editUser"
                      variant="secondary"
                      @click="editUserOn"
                      :hidden="ctrledit != true"
                      >Editar<i class="fas fa-user-edit"></i>
                    </b-button>
                    <b-button
                      id="cancelEdit"
                      variant="secondary"
                      class="mr-2"
                      @click="cancelEditUser"
                      :hidden="ctrledit"
                      >Cancelar <i class="far fa-times-circle"></i
                    ></b-button>
                    <b-button
                      id="saveEdit"
                      variant="success"
                      type="submit"
                      @click="actualizarDatosUser"
                      :hidden="ctrledit"
                      >Guardar
                      <i class="fas fa-save"></i>
                      <b-spinner
                        small
                        v-if="spnactiveInfo"
                        label="Spinning"
                      ></b-spinner>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
              <hr />
              <!-- Nueva Pass -->
              <b-row
                class="d-flex justify-content-center mt-2"
                v-if="shownewPass"
              >
                <b-col class="border rounded" cols="5">
                  <!-- Input -->
                  <b-row class="d-flex justify-content-center">
                    <b-col>
                      <b-form-group
                        id="newPass"
                        label="Nueva Contraseña"
                        label-for="input-newPass"
                      >
                        <b-form-input
                          id="input-newPass"
                          v-model="newData.newPass"
                          type="password"
                        ></b-form-input>
                      </b-form-group>
                      <!-- Buttons -->
                      <b-col class="pt-0 pb-0 d-flex justify-content-center">
                        <b-button
                          variant="secondary"
                          class="text-dark mr-2"
                          @click="shownewPass = false"
                          >Cancelar</b-button
                        >
                        <b-button
                          variant="success"
                          class="text-white"
                          @click="actualizarPass"
                          >Actualizar
                          <b-spinner
                            small
                            v-if="spnactiveSPass"
                            label="Spinning"
                          ></b-spinner
                        ></b-button>
                      </b-col>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- Nuevo Alegra Id -->
              <b-row
                class="d-flex justify-content-center mt-2"
                v-if="shownewAlegraId"
              >
                <b-col class="border rounded" cols="5">
                  <!-- Input -->
                  <b-row class="d-flex justify-content-center">
                    <b-col>
                      <b-form-group
                        id="newAlegraId"
                        label="Nuevo Alegra ID"
                        label-for="input-newAlegraId"
                      >
                        <b-form-input
                          id="input-newAlegraId"
                          v-model="newDataAlegra.newAlegraId"
                          type="text"
                        ></b-form-input>
                      </b-form-group>
                      <!-- Buttons -->
                      <b-col class="pt-0 pb-0 d-flex justify-content-center">
                        <b-button
                          variant="secondary"
                          class="text-dark mr-2"
                          @click="shownewAlegraId = false"
                          >Cancelar</b-button
                        >
                        <b-button
                          variant="success"
                          class="text-white"
                          @click="actualizarAlegraId"
                          >Actualizar
                          <b-spinner
                            small
                            v-if="spnactiveSPass"
                            label="Spinning"
                          ></b-spinner
                        ></b-button>
                      </b-col>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- Botones -->
              <b-row class="d-flex justify-content-center">
                <b-col md="auto">
                  <b-button variant="primary" @click="viewactualizarPass"
                    >Crear Nueva Contraseña</b-button
                  >
                </b-col>
                <b-col md="auto">
                  <b-button variant="primary" @click="viewactualizarAlegraId"
                    >Actualizar Alegra Id</b-button
                  >
                </b-col>
                <b-col md="auto">
                  <button
                    class="btn btn-standar"
                    @click="enviarCorreoRecuperar"
                  >
                    Enviar Enlace para Restablecer Contraseña
                    <b-spinner
                      small
                      v-if="spnactiveR"
                      label="Spinning"
                    ></b-spinner>
                  </button>
                </b-col>
                <b-col md="auto">
                  <b-button variant="success" @click="enviarCorreoCompra">
                    Enviar Dirección de compra
                    <b-spinner
                      small
                      v-if="spnactiveD"
                      label="Spinning"
                    ></b-spinner>
                  </b-button>
                </b-col>
                <b-col md="auto">
                  <b-button variant="warning" @click="convertirComercial">
                    Convertir a cliente Comercial
                    <b-spinner
                      small
                      v-if="spnactiveCom"
                      label="Spinning"
                    ></b-spinner>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-modal>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import * as clientService from "../../services/clients.module.js";
import * as auth from "../../services/auth.module.js";

export default {
  name: "UserInfo",
  props: ["role"],
  data() {
    return {
      show: true,
      ctrledit: true,
      shownewPass: false,
      shownewAlegraId: false,
      spnactiveD: false,
      spnactiveCom: false,
      spnactiveR: false,
      spnactiveSPass: false,
      spnactiveInfo: false,
      searchUsers: "",
      options: [
        { value: "ST", text: "Sin Tarjeta" },
        { value: "STP", text: "Sin tarjeta Sin Volumen" },
        { value: "E", text: "Económico" },
        { value: "EP", text: "Económico Sin Volumen" },
      ],
      headerUsers: [
        { text: "Nombre", value: "full_name", align: "center" },
        { text: "Identificación", value: "cedula", align: "center" },
        { text: "Email", value: "correo", align: "center" },
        { text: "Teléfono", value: "telefono", align: "center" },
        {
          text: "Fecha Nac.",
          value: "fecha_nacimiento",
          align: "center",
        },
        // { text: "Tipo Perfil", value: null },
        { text: "Casillero", value: "casillero_full", align: "center" },
        { text: "Código Cliente", value: "alegra_id", align: "center" },
        {
          text: "Día de Registro",
          value: "created_at",
          align: "center",
        },
        // { text: "Estado", value: null },
        { text: "Accion", value: "accion", align: "center" },
      ],
      usuarios: [],
      list_data: [],
      selectUser: {
        id: "",
        nombre: "",
        apellido: "",
        correo: "",
        cedula: "",
        telefono: "",
        fecha_nacimiento: "",
        tipo_plan: "",
        casillero: "",
      },
      slUserbckp: {},
      newData: {
        id: "",
        newPass: "",
      },
      newDataAlegra: {
        id: "",
        newAlegraId: "",
      },
    };
  },
  methods: {
    enviarCorreoCompra() {
      this.spnactiveD = true;
      auth.EnviarDataCompra(this.selectUser).then((result) => {
        if (result.status == 200) {
          Vue.$toast.open({
            message: result.message,
            type: "success",
            duration: 10000,
          });
          this.spnactiveD = false;
        } else {
          Vue.$toast.open({
            message: result.message,
            type: "error",
            duration: 10000,
          });
        }
      });
    },
    convertirComercial() {
      this.spnactiveCom = true;
      Vue.$toast.open({
        message: "Conviertiendo cliente, aguanta...",
        type: "info",
        duration: 7000,
      });
      auth
        .ConvertirComercial(this.selectUser.id)
        .then((res) => {
          if (res.status == 200) {
            Vue.$toast.open({
              message: "El cliente ha sido actualizado a su cuenta Comercial",
              type: "success",
              duration: 10000,
            });
            this.getClients();
          } else {
            Vue.$toast.open({
              message:
                "Pa' sete sincero, no se que paso pero no se pudo actualizar la vaina :/",
              type: "error",
              duration: 10000,
            });
          }
          this.spnactiveCom = false;
        })
        .catch((error) => console.log(error));
    },
    crearClienteAlegra(item) {
      let id = "#spn" + item.id;
      $(id).removeAttr("hidden");
      auth.RegistrarClienteAlegraFromUserInfo(item).then((result) => {
        if (result.status == 201) {
          Vue.$toast.open({
            message: "Alegra id registrado en el cliente",
            type: "success",
            duration: 10000,
          });
          this.usuarios = [];
          this.getClients();
          $(id).attr("hidden", "hidden");
        } else {
          Vue.$toast.open({
            message: result.data.message,
            type: "error",
            duration: 10000,
          });
          $(id).attr("hidden", "hidden");
        }
      });
    },
    enviarCorreoRecuperar() {
      this.spnactiveR = true;
      auth.RecuperarContrasena(this.selectUser).then((result) => {
        if (result.status == 200) {
          Vue.$toast.open({
            message: result.message,
            type: "success",
            duration: 10000,
          });
          this.spnactiveR = false;
        } else {
          Vue.$toast.open({
            message: result.message,
            type: "error",
            duration: 10000,
          });
        }
      });
    },
    verInfo(item) {
      this.slUserbckp = {};
      var data = JSON.stringify(item);
      this.selectUser = JSON.parse(data);
      this.slUserbckp = JSON.parse(data);
    },
    viewactualizarPass() {
      this.shownewPass = true;
    },
    viewactualizarAlegraId() {
      this.shownewAlegraId = true;
    },
    actualizarDatosUser(event) {
      this.spnactiveInfo = true;
      event.preventDefault();
      clientService.UpdateUserData(this.selectUser).then((result) => {
        if (result.status == 200) {
          Vue.$toast.open({
            message: result.message,
            type: "success",
            duration: 10000,
          });
          this.ctrledit = true;
          this.$bvModal.hide("userinfoModal");
          this.limpiarSelectUser();
          this.usuarios = [];
          this.getClients();
          this.slUserbckp = {};
          this.spnactiveInfo = false;
        } else if (result.status == 500) {
          Vue.$toast.open({
            message: "Ya existe una cuenta con la cedula introducida",
            type: "error",
            duration: 10000,
          });
          var data = JSON.stringify(this.slUserbckp);
          this.selectUser = JSON.parse(data);
        } else {
          Vue.$toast.open({
            message: result.message,
            type: "error",
            duration: 10000,
          });
        }
      });
    },
    actualizarPass() {
      this.spnactiveSPass = true;
      this.newData.id = this.selectUser.id;
      clientService.UpdateUserPass(this.newData).then((result) => {
        if (result.status == 200) {
          Vue.$toast.open({
            message: result.message,
            type: "success",
            duration: 10000,
          });
          this.newData = { id: "", newPass: "" };
          this.shownewPass = false;
          this.spnactiveSPass = false;
        } else {
          Vue.$toast.open({
            message: result.message,
            type: "error",
            duration: 10000,
          });
        }
      });
    },
    actualizarAlegraId() {
      this.spnactiveSPass = true;
      this.newDataAlegra.id = this.selectUser.id;
      clientService.UpdateUserAlegraId(this.newDataAlegra).then((result) => {
        if (result.status == 200) {
          Vue.$toast.open({
            message: result.message,
            type: "success",
            duration: 10000,
          });
          this.$bvModal.hide("userinfoModal");
          this.getClients();
          this.newDataAlegra = { id: "", newAlegraId: "" };
          this.shownewAlegraId = false;
          this.spnactiveSPass = false;
        } else {
          Vue.$toast.open({
            message: result.message,
            type: "error",
            duration: 10000,
          });
        }
      });
    },
    editUserOn() {
      this.ctrledit = false;
    },
    cancelEditUser() {
      var data = JSON.stringify(this.slUserbckp);
      this.selectUser = JSON.parse(data);
      this.ctrledit = true;
    },
    limpiarSelectUser() {
      this.selectUser = {
        id: "",
        nombre: "",
        apellido: "",
        correo: "",
        cedula: "",
        telefono: "",
        fecha_nacimiento: "",
        tipo_plan: "",
        casillero: "",
      };
    },
    getClients() {
      this.list_data = [];
      this.usuarios = [];
      clientService.GetAllClientesInfo("all").then((clients) => {
        this.usuarios = clients;
        clients.forEach((element) => {
          this.list_data.push({
            nombre: element.nombre,
            apellido: element.apellido,
            correo: element.correo,
          });
        });
      });
    },
  },
  mounted() {
    this.getClients();
    console.log(this.role);
  },
};
</script>

<style>
.btn-standar {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-standar:hover {
  color: #fff;
  background-color: #3496ff;
  border-color: #3496ff;
}
</style>

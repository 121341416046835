<template>
  <v-app>
    <div class="card shadow border-0">
      <h4 class="card-title text-white bg-dark h4 p-4">
        <em class="ri-wallet-2-line"></em> Facturas
        <a :href="link" target="_blank" class="btn btn-warning fw-bold text-dark float-right" data-bs-toggle="tooltip"
          id="facturas" title="Ver estado de cuenta en el sistema de cuentas.">Estado de cuenta <em
            class="ri-external-link-line"></em></a>
      </h4>
      <div class="card-body">
        <v-data-table class="elevation-2" :headers="headersOpenFacturas" :items="openFacturas">
          <template v-slot:[`item.accion`]="{ item }">
            <b-button small class="text-white" variant="warning" @click="verInfo(item)">
              <i class="fas fa-eye"></i>
              Ver
            </b-button>
          </template>
          <template v-slot:[`item.pagada`]="{ item }">
            <v-icon v-if="item.pagada == 0" medium class="mr-2 text-danger" v-b-tooltip.hover title="Por Cobrar">
              mdi-block-helper
            </v-icon>
            <v-icon v-if="item.pagada == 1" medium class="mr-2 text-success" v-b-tooltip.hover title="Pagada">
              mdi-check
            </v-icon>
          </template>
        </v-data-table>

        <!-- Modal -->
        <v-dialog v-model="showModal" max-width="800px">
          <v-card style="max-height: 80vh; overflow-y: auto;">
            <v-card-title class="bg-light-gray">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div>
                  <img src="/img/shipex/logoBlack.png" alt="SHIPEX Logo"
                    style="width: 40px; margin-right: 8px;" />
                  <span class="text-h5"><strong>Factura Detalle</strong></span>
                </div>
                <Promocion :factura="factura" />
                <v-btn icon @click="showModal = false" color="red" dark>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text class="p-4 bg-light-gray">
              <div class="d-flex justify-content-between">
                <div class="invoice-left">
                  <p><strong>Factura #:</strong> <strong>{{ factura.numero }}</strong></p>
                  <p><strong>Fecha de Facturación:</strong></p>
                  <p>{{ factura.date }}</p>
                  <p><strong>Fecha de Vencimiento:</strong></p>
                  <p>{{ factura.dueDate }}</p>
                </div>

                <!-- Nueva sección en el centro -->
                <div class="invoice-center text-center">
                  <p><strong>Facturado a:</strong> <strong>{{ factura.client.name }}</strong></p>
                  <p><strong>Email:</strong> {{ factura.client.email }}</p>
                </div>

                <div class="invoice-right">
                  <p><strong>Comercio:</strong> 
                    <span class="shi-text"><strong>Ship</strong></span><span class="pex-text"><strong>ex</strong></span>
                  </p>
                  <p><strong>Balance:</strong> ${{ factura.balance }}</p>
                  <p><strong>Total Pagado:</strong> ${{ factura.total }}</p>
                </div>
              </div>

              <v-divider class="my-3"></v-divider>

              <p class="mb-2"><strong>Artículos</strong></p>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th>Artículo</th>
                    <th>Cantidad</th>
                    <th>Precio</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in factura.items" :key="index">
                    <td>{{ item.description }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>${{ item.price }}</td>
                    <td>${{ item.quantity * item.price }}</td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-divider class="my-3"></v-divider>

              <div class="d-flex justify-content-between">
                <p><strong>Total General:</strong></p>
                <p>${{ factura.total }}</p>
              </div>

              <v-divider class="my-3"></v-divider>

              <p class="text-center"><strong>Términos:</strong></p>
              <p>{{ factura.termsConditions }}</p>
            </v-card-text>
          </v-card>
          
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>

<script>
import * as clientService from "../../services/client.module.js";
import Promocion from "./Promocion.vue";

export default {
  name: "Facturas",
  props: ["alegraid"],
  components:{
    Promocion
  },
  data() {
    return {
      link: "",
      openFacturas: [],
      showModal: false,
      factura: {
        numero: 0,
        date: '',
        dueDate: '',
        client: {
          name: '',
          email: '',
        },
        items: [],
        subtotal: 0.0,
        total: 0.0,
        balance: 0.0,
        warehouse: {
          name: ''
        },
        paymentNumber: '',
        termsConditions: ''
      },
      headersOpenFacturas: [
        { text: "Fecha", value: "date", align: "center" },
        { text: "Fecha de Vencimiento", value: "dueDate", align: "center" },
        { text: "Factura #", value: "numberTemplate.number", align: "center" },
        { text: "Total", value: "total", align: "center" },
        { text: "Pagada", value: "pagada", align: "center" },
        { text: "Acciones", value: "accion", align: "center" },
      ],
    };
  },
  methods: {
    getEstadoCuentaLink() {
      clientService.GetAlegraClientInfo(this.alegraid).then((alegraInfo) => {
        this.link = alegraInfo.statementLink;
        console.log(alegraInfo.statementLink);
      });
    },
    getFacturasAbiertas() {
      if (this.alegraid === 0) {
        this.openFacturas = [];
      } else {
        clientService.GetFacturasAbiertas(this.alegraid).then((facturas) => {
          this.openFacturas = facturas.map(factura => {
            factura.pagada = factura.balance === 0 ? 1 : 0;
            return factura;
          });
        });
      }
    },
    verInfo(factura) {
      this.factura = {
        numero: factura.numberTemplate.number,
        date: factura.date,
        discount: factura.discount,
        dueDate: factura.dueDate,
        client: factura.client,
        email: factura.email,
        items: factura.items,
        subtotal: factura.subtotal,
        total: factura.total,
        balance: factura.balance,
        warehouse: factura.warehouse,
        termsConditions: factura.termsConditions
      };
      this.showModal = true;
    },
  },
  mounted() {
    this.getEstadoCuentaLink();
    this.getFacturasAbiertas();
    new bootstrap.Tooltip(document.getElementById("facturas"));
  },
};
</script>

<style>
.bg-light-gray {
  background-color: #f8f8f8;
}

.invoice-left {
  text-align: left;
}

.invoice-center {
  text-align: center;
}

.invoice-right {
  text-align: right;
}

.shi-text {
  color: red;
}

.pex-text {
  color: black;
}

.v-simple-table th,
.v-simple-table td {
  text-align: center;
  padding: 8px;
}

.v-simple-table th {
  background-color: #f0f0f0;
}
</style>

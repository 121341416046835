<template>
  <v-app>
    <div class="border-0 p-4 bg-light-gray">
      <v-btn @click="toggleCuponModal" color="red" outlined class="activador-cupon-btn">
        Activar Cupón
        <span class="mdi mdi-qrcode-scan icono-cupon"></span>
      </v-btn>
      <v-dialog v-model="showCuponModal" max-width="500px">
        <v-card>
          <v-card-title class="headline text-center">Ingresa tu Cupón</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field v-model="cupon" label="Ingresa tu cupón" outlined dense
              hint="Ingresa el código de tu cupón aquí" class="mt-3" />
            <v-btn @click="validCuponInsertByClient" color="primary" :disabled="loadingBtn || !cupon"
              :loading="loadingBtn" class="mt-2" block>
              Activar Cupón
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showCuponInfoModal" max-width="500px">
        <v-card>
          <v-card-title class="d-flex justify-center pt-4 pb-0">
            <img src="/img/shipex/logoBlack.png" alt="Shipex Logo" class="logo-shipex" />
          </v-card-title>
          <v-card-subtitle class="text-center mb-4">Detalles del Cupón</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text class="coupon-details">
            <v-row class="pt-2 pb-4">
              <v-col cols="12">
                <div class="coupon-detail"><b>Nombre:</b> {{ info.nombre }}</div>
                <div class="coupon-detail"><b>Valor:</b> {{ info.valor_descuento }}</div>
                <div class="coupon-detail"><b>Descripción:</b> {{ info.descripcion }}</div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="primary" @click="refreshPage">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import * as PromocionServices from "../../services/promociones.module";
import * as GeneralHelper from "../../helper/general.module";

export default {
  name: "Promocion",
  props: {
    factura: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      showCuponModal: false,
      showCuponInfoModal: false,
      loadingBtn: false,
      cupon: "",
      info: {
        nombre: "",
        fecha_inicio: "",
        valor_descuento: '',
        vigencia_info: {
          nombre: "",
          valor: "",
        },
        descripcion: "",
      },
    };
  },
  methods: {
    toggleCuponModal() {
      this.showCuponModal = !this.showCuponModal;
    },
    applyDiscountToItems() {
      if (this.info.valor_descuento) {
        this.factura.items.forEach(item => {
          item.discount = this.info.valor_descuento;
        });
      }
    },
    async validCuponInsertByClient() {
      this.loadingBtn = true;
      try {
        const hasDiscount = this.factura.items.some(item => item.discount > 0);

        if (hasDiscount) {
          alert("No puedes aplicar más descuento en esta factura.");
          return;
        }

        const res = await PromocionServices.ValidCuponInsertByClient({ cupon: this.cupon });
        GeneralHelper.ShowMessages(res);

        if (res.status === 200) {
          this.showCuponModal = false;
          await this.checkIfClientHaveActivePromo();

          this.applyDiscountToItems();

          await this.editInvoice();

          this.showCuponInfoModal = true;
        }
      } finally {
        this.loadingBtn = false;
      }
    },
    async editInvoice() {
      try {
        const updatedData = {
          items: this.factura.items,
        };
        const invoiceId = this.factura.numero;
        const updatedInvoice = await PromocionServices.EditarFacturaAlegra(invoiceId, updatedData);
        console.log("Factura actualizada:", updatedInvoice);
      } catch (error) {
        console.error("Error al editar la factura:", error);
      }
    },

    async checkIfClientHaveActivePromo() {
      this.loadingBtn = true;

      const res = await PromocionServices.CheckIfClientHaveActivePromo();
      this.loadingBtn = false;
      if (!!res) {
        this.info = res;
      } else {
        this.Toast.fire({
          icon: "info",
          title: "Sin Cupón Activo!!!",
        });
      }
    },
    refreshPage() {
      this.showCuponInfoModal = false;
      window.location.reload();
    }
  },
  mounted() {
    this.checkIfClientHaveActivePromo();
  },
};
</script>

<style>
.activador-cupon-btn {
  border: 2px solid red;
  font-weight: bold;
}

.logo-shipex {
  max-width: 120px;
  margin-bottom: 10px;
}

.icono-cupon {
  font-size: 24px;
  color: gray;
}

.coupon-details {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.coupon-detail {
  font-size: 16px;
  margin: 5px 0;
}
</style>
